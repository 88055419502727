/* VARS */
@import "_theme.scss";

:root {
  --light: #dbdbdb;
  --primary: #1f4692;
  --secondary: #633284;
  --main: $main;
  --surface: #191a1f;
}

.app {
  padding: 0 10px;
  width: 100%;
  max-width: 900px;
  min-height: calc(100% - 130px);
  margin: 0 auto;
}

html,
body,
#root {
  height: 100%;
  font: 14px/1.21 "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  color: white;
  background-color: #0f0f11 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

#root {
  height: calc(100% - 15px) !important;
}

.header__body {
  min-height: 100%;
}

* {
  box-sizing: border-box;
  border-color: #969aa0;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8f8f;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8f8f;
}

::-ms-input-placeholder {
  color: #8f8f8f;
}

input:-webkit-autofill,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $main inset;
  -webkit-box-shadow: 0 0 0 30px $main inset;
  -webkit-text-fill-color: #c1c1c1;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px $main inset;
  -webkit-box-shadow: 0 0 0 30px $main inset;
  -webkit-text-fill-color: #c1c1c1;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #c1c1c1;
}

h1 {
  text-align: center;
}

.h2__header {
  text-align: center;
  margin-top: 0;
  font-size: 18px;
}

.h3__header {
  font-weight: 100;
  text-align: left;
  font-size: 25px;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

.lander {
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
}

.inner {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: lightgrey;
  justify-content: center;
}

.banner {
  border-bottom: 1px solid #272930;
  text-align: center;
  height: 60px;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  color: #dddddd;
  margin-top: 0;
  justify-content: space-between;
  margin-bottom: 10px;
}

.material__btn {
  height: 60px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature__box {
  h2 {
    color: #e9e9e9 !important;
  }
}

.backdrop {
  opacity: 0.5;
  width: 100%;
  background: black;
  min-height: 100%;
  position: fixed;
  z-index: 5;
}

.menu__grid__container {
  cursor: pointer;
  height: 60px;
  font-size: 27px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu__grid {
  height: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #444;
  cursor: pointer;

  div {
    background-color: white;
    margin: 2px 0px;
    height: 3px;
    border-radius: 5px;
    width: 27px;
    cursor: pointer;
  }
}

.close {
  position: relative;
  margin: 0;
  height: 30px;
  color: #828282;
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: right;

  svg {
    margin-top: 15px;
    margin-right: 20px;
    margin-left: auto;
    font-size: 23px !important;
  }
}

.menu {
  position: fixed;
  max-width: 400px;
  float: left;
  top: 0;
  transition-duration: 500ms;
  left: 0;
  background: #191a1f;
  height: 100%;
  z-index: 1000;
  width: 350px;
  text-align: left;
  padding: 0 15px;

  h2 {
    font-size: 26px;
    font-weight: 300;
  }

  p {
    margin: 10px 0 !important;
    font-size: 21px;
    padding: 15px 20px;
    height: 50px;
    color: #bcbcbc;
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 100% !important;
    justify-content: left !important;
  }

  p:hover {
    color: #7276c3;
    background: #292832;
    border-radius: 5px;
  }

  p:focus {
    color: #7276c3;
    background: #292832;
    border-radius: 5px;
  }

  svg {
    margin-right: 20px;
    font-size: 28px;
    width: 39px !important;
  }
}

.navbar p {
  height: 60px;
  width: 95px;
  display: flex;
  cursor: pointer;
  font-weight: 600;
  margin: 0 !important;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0 15px;
  font-size: 16px;

  &:focus {
    background: #292832;
  }
}

.navbar p:hover,
.menu__grid__container:hover {
  background: #292832;
}

/* Auth */

.error {
  color: rgb(219, 31, 62);
}

form {
  margin-bottom: 150px;
  max-width: 555px;
  margin: 0 auto;
}

form h3 {
  padding-bottom: 8px;
  font-weight: 600;
  margin-top: 30px;
}

select {
  width: 100%;
  background: #2b2c30;
  color: #818181;
  border: none;
  margin-bottom: 15px;
}

option {
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

/* Transits */
.input {
  width: 100%;
}

.transit__card {
  min-width: 380px;
  max-width: 385px !important;
  min-height: 300px;
  background: var(--surface);
  position: relative;
  border-radius: 10px;
  color: #d1d1d1;
  margin: 25px 0;
  padding-bottom: 15px;
}

.transit__card h3 {
  text-align: left;
  margin: 5px 0;
  color: white;
}

.card__body {
  margin: 0px 10px;
}

.starmap {
  margin: 0 auto;
  width: 100%;
  height: 220px;
  width: 100%;
}

.data {
  margin: 0 auto;
  width: max-content;
  margin-bottom: 10px;
}

.depth__display {
  background: black;
  height: 220px;
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.depth__display p {
  position: absolute;
  top: 22%;
  transform: translate(-43%, -22%);
  left: 43%;
  font-size: 21px;
}

.circle {
  position: absolute;
  height: 90px;
  width: 90px;
  background: #303f89;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  top: 25%;
  left: 15%;
  transform: translate(-15%, -25%);
  text-align: center;
}

.mag {
  top: 80% !important;
  left: 50% !important;
  transform: translate(-50%, -80%) !important;
}

.vis {
  left: 85% !important;
  transform: translate(-85%, -25%) !important;
}

/* planner */
.planner__card {
  width: 400px;
  max-width: 425px;
}

/* Icons */
.toggle {
  position: absolute;
  width: 65px;
  display: flex;
  flex-wrap: wrap;
  height: 185px;
  bottom: 0;
  right: 0;

  button {
    border-radius: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    border: none;
    margin: 5px 5px;
    color: #d6d6d6;
  }
}

.rounded__btn {
  cursor: pointer;
}

.planner__selector {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 10px;
  font-weight: 400 !important;
  font-size: 20px !important;
  cursor: pointer;
}

/* display full btn */
.full {
  background-color: #383b44;
}

.full:hover {
  background-color: #212329;
}

.full:focus {
  background-color: #212329;
}

.filters__model {
  background: var(--surface);
  padding-left: 20px;
  border-radius: 10px;
  padding-right: 20px;
  padding-bottom: 15px;
}

.filters__model:hover {
  cursor: pointer;
}

.input__component {
  position: relative;
  margin: 10px 5px;
}

.input__component svg {
  position: absolute;
  font-size: 18px;
  top: 50%;
  transform: translate(10px, -50%);
  color: #8f8f8f;
}

/* Detail */
.aladin-zoomControl {
  background-color: transparent !important;
}

.zoomPlus,
.zoomMinus {
  color: white !important;
  background: var(--primary) !important;
  border: none !important;
  margin: 5px 5px !important;
}

.aladin-location,
.aladin-fov {
  margin-left: 6px;
  border-radius: 3px;
  background-color: #312f3b80 !important;
  color: #3a65bd !important;
}

.aladin-location {
  margin-top: 6px;
}

.aladin-fov {
  margin-bottom: 6px;
}

#aladin__container {
  border: none;
  background-color: transparent !important;
  border-radius: 10px;
}

#aladin__container canvas {
  border-radius: 10px;
}

/* Chakra */
button:focus {
  box-shadow: 0 0 0 3px #1f4793 !important;
}

.chakra-form__label {
  color: #c7c7c7;
}

.screen__heading {
  font-size: 32px !important;
}

body::-webkit-scrollbar { background-color: rgb(61, 61, 61)}
body::-webkit-scrollbar-thumb { background-color: darkgrey;}


.spinner {
  svg {
    margin: 0 auto !important;
  }
}